<template>
  <div style="width:100%;height:177vw;background: #F9F9F9;">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">{{ name }}</p>
    </div>
    <div class="hos-midd" v-for="(item, index) in hosList" :key="index">
      <img class="img-midd" :src="item.hospital_image" alt="" />
      <div class="hos-middle">
        <div class="hos-middle-left">
          <h3>{{ item.hospital_name }}</h3>
          <p>{{ item.hospital_synopsis }}</p>
          <span
            ><img src="../assets/images/phone.png" alt="" />
            <i>{{ item.hospital_phone }}</i></span
          >
          <span
            ><img src="../assets/images/形状结合.png" alt="" />
            <i>{{ item.hospital_address }}</i></span
          >
        </div>
        <div class="hos-middle-right">
          <a
            class="map-link"
            href="https://wx.weilaihos.com/app/main?hi=24DXDB72ZPUF"
          >
            <img src="../assets/images/地图.png" alt="" />
            <span>来院导航</span>
          </a>
        </div>
      </div>
    </div>
    <div class="hos-bottom">
      <p class="hos-bottom-left" @click="btn">
        <img src="../assets/images/挂号.png" alt="" />
        <span style="color:#333333">预约挂号</span>
      </p>
      <div class="hos-bottom-right">
        <a href="https://wx.weilaihos.com/app/main?hi=24DUPW4RQG78">
          <img src="../assets/images/自我诊断.png" alt="" />
          <span style="color:#333333">自我诊测</span>
        </a>
        <a href="https://msg.weilaihos.com/#/hospitalDetail?id=3">
          <img src="../assets/images/在线缴费.png" alt="" />
          <span>在线缴费</span>
        </a>
        <a href="https://msg.weilaihos.com/#/hospitalDetail?id=3">
          <img src="../assets/images/报告查询.png" alt="" />
          <span>报告查询</span>
        </a>
        <a href="https://msg.weilaihos.com/#/hospitalDetail?id=3">
          <img src="../assets/images/排队候诊.png" alt="" />
          <span>排队候诊</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '../utils/config'
import { getHospitlListDetail } from '../services/home'

export default {
  data() {
    return {
      hosList: [],
      name: ''
    }
  },
  async created() {
    const res = await getHospitlListDetail({ id: this.$route.query.id })
    if (res.data.code === 200) {
      res.data.data.forEach(e => {
        e.hospital_image = baseUrl + e.hospital_image
        this.name = e.hospital_name
      })
      this.hosList = res.data.data
    } else {
      return res.data.msg
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    btn() {
      location.href =
        'https://wx.weilaihos.com/app/main?hi=24DB42GNFLPP&user_id=' +
        this.$route.query.user_id
    }
  }
}
</script>

<style scoped>
.back {
  z-index: 100;
}
.hos-top {
  height: 180px;
  width: 100%;
}
.hos-top img {
  display: block;
  height: 100%;
  width: 100%;
}
.hos-midd {
  display: flex;
  flex-direction: column;
  margin-top: 46px;
}
.hos-midd .img-midd {
  display: block;
  height: 185px;
}
.hos-middle {
  padding: 10px 20px;
  height: 42vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
}
.hos-middle-left {
  width: 62vw;
  display: flex;
  flex-direction: column;
}
.hos-middle-left h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}
.hos-middle-left p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 10px;
}
.hos-middle-left span {
  width: 40vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
}
.hos-middle-left span img {
  display: inline-block;
  height: 16px;
  width: 16px;
}
.hos-middle-left span i {
  font-style: normal;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-left: 2vw;
}

.map-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map-link span {
  color: #5ca5ff;
  font-size: 14px;
}
.hos-bottom {
  height: 200px;
  width: 100%;
  margin-top: 3vw;
  display: flex;
  background: #ffffff;
}
.hos-bottom-left {
  height: 200px;
  width: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #eeeeee;
  margin: 0;
}
.hos-bottom-left span {
  margin-top: 5px;
}
.hos-bottom-right {
  width: 65vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.hos-bottom-right a {
  width: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hos-bottom-right a span {
  margin-top: 5px;
  color: #999999;
}
</style>
